<template>
  <div class="service">
    <div class="title">
      <span>资源库 /</span><span class="black">音频库</span>
    </div>
    <el-card class="condition_style2">
      <el-form :inline="true" class="flex" :model="searchForm">
        <el-form-item>
          <el-button type="primary" size="small" @click="add()">上传音频</el-button>
        </el-form-item>
        <div>
          <el-form-item>
            <el-input v-model="searchForm.fileTitle" placeholder="请输入文件标题" suffix-icon="el-icon-search" clearable>
            </el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" size="small" @click="getList(true)">查询</el-button>
          </el-form-item>
        </div>
      </el-form>
      <el-table :data="tableData" style="width: 100%" class="table" :height="tableHeight">
        <el-table-column type="index" label="序号" width="60" align="center" fixed>
          <template slot-scope="scope">{{scope.$index+(current-1) * size + 1}}</template>
        </el-table-column>
        <el-table-column prop="fileTitle" label="音频名称" min-width="120" align="center"></el-table-column>
        <el-table-column prop="fileDesc" label="音频描述" min-width="120" align="center"></el-table-column>
        <el-table-column prop="format" label="文件类型" min-width="120" align="center"></el-table-column>
        <el-table-column prop="fileSize" label="大小" min-width="120" align="center"></el-table-column>
        <el-table-column prop="url" label="url" min-width="200" align="center"></el-table-column>
        <el-table-column prop="updateTime" label="最后操作时间" min-width="180" align="center"></el-table-column>
        <el-table-column prop="updateUser" label="最后操作人" min-width="180" align="center"></el-table-column>
        <el-table-column fixed="right" label="操作" width="120" align="center">
          <template slot-scope="scope">
            <div>
              <el-button type="text" size="small" class="ge" @click="changeState(scope.row)">删除</el-button>
              <span class="ge">|</span>
              <el-button type="text" size="small" class="ge" @click="add(scope.row.id)"> 编辑</el-button>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <pagination :page="current" :size="size" :total="total" @handleSizeChange="handleSizeChange"
        @handleCurrentChange="handleCurrentChange"></pagination>
    </el-card>

    <!-- 新增\编辑 -->
    <modal :obj="{
        title: edit ? '编辑' : '新增',
        dialog: addDialog,
        dialogText: 'addDialog',
        form: addForm,
        ref: 'addForm',
      }" @submit="addSubmit">
      <el-form :model="addForm" label-width="130px" :rules="rules" ref="addForm">
        <el-form-item label="音频名称：" prop="fileTitle">
          <el-input v-model="addForm.fileTitle" placeholder="请输入名称" maxlength="18"></el-input>
        </el-form-item>
        <el-form-item label="音频描述:" prop="fileDesc">
          <el-input type="textarea" v-model="addForm.fileDesc" :rows="4" placeholder="请输入描述" maxlength="300"
            show-word-limit></el-input>
        </el-form-item>
      </el-form>
    </modal>
  </div>
</template>

<script>
  import pagination from "../../../components/pagination";
  import modal from "../../../components/modal";
  import {
    deleteFile,
    fileInfo,
    getFileList,
    fileUpdate,
    files1,
    files2
  } from "../../../api/api";
  export default {
    components: {
      pagination,
      modal,
    },
    data() {
      return {
        size: 10, //每页条数
        current: 1, //当前条数
        total: 0,
        tableHeight: this.$util.getHeight(310),
        searchForm: {
          fileTitle: "",
          type: 3
        },
        tableData: [],
        addDialog: false,
        edit: "",
        addForm: {
          type: 3,
          id: "",
          fileTitle: "",
          fileDesc: "",
        },
        rules: {
          fileTitle: [{
            required: true,
            message: "请输入文件标题"
          }],
        },

      };
    },
    computed: {},

    created() {
      this.getList();
    },
    mounted() {
      window.addEventListener('resize', () => {
        this.tableHeight = this.$util.getHeight(310)
      })
    },
    methods: {
      getList(val) {
        this.$request(getFileList(this.current, this.size), this.searchForm).then((res) => {
          if (res.code == 0) {
            let data = res.data;
            this.tableData = data.records
            this.total = Number(data.total);
          }
        });
      },

      //   新增\编辑-弹框
      add(val) {
        if (!val) {
          this.$router.push({
            name: "addAudio"
          })
        } else {
          this.addDialog = true;
          this.getInfo(val);
        }
        this.addForm = {
          type: 3,
          id: "",
          fileTitle: "",
          fileDesc: "",

        };
        if (this.$refs.addForm) {
          this.$refs.addForm.resetFields();
        }
        this.edit = val;
      },
      // 查询单个信息
      getInfo(val) {
        this.$request(fileInfo, {
          id: val
        }).then((res) => {
          if (res.code === 0) {
            this.addForm = res.data;
          }
        });
      },
      //   新增编辑-上传接口
      addSubmit() {
        this.$refs.addForm.validate((result) => {
          if (result) {
            this.$request(fileUpdate, this.addForm).then((res) => {
              if (res.code === 0) {
                this.$util.message("suc", "操作成功");
                this.addDialog = false;
                this.getList();
              }
            });
          }
        });
      },
      // 启用禁用
      changeState(val) {
        this.$util.isSwal("确定删除？", this.changeStateSubmit, val);
      },
      changeStateSubmit(val) {
        this.$request(deleteFile, {
          id: val.id
        }).then((res) => {
          if (res.code === 0 && res.data == true) {
            this.$util.message("suc", "操作成功");
            this.getList();
          }

        })


      },
      handleSizeChange(val) {
        this.size = val;
        this.getList();
      },
      handleCurrentChange(val) {
        this.current = val;
        this.getList();
      },
    },
  };
</script>

<style lang="less" scoped>
  .service {
    .add {
      padding-top: 3px;
      margin-right: 24px;
    }
  }
</style>